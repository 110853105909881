<template>
  <div class="dashboard">
    <promoter-link-modal ref="promoterLinkModal" />
    <div id="Content">
      <div class="content-inner">
        <div v-if="false" class="divider"></div>
<!--        <div class="button-container">
          <diyobo-button
            txt="Affiliate Page"
            class="primary"
            @click="redirect"
          />
        </div>-->
        <tab-tote
          :tabs="navTabs"
          :value="navTabSelected"
          v-on:click="onClickNavTab"
        />
        <div class="divider"></div>
        <router-view :pageURL="link" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.dashboard {
  #Content {
    .content-inner {
      padding-top: 32px;
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    // end divider

    .button-container {
      display: flex;
      margin-bottom: 32px;
      justify-content: center;
    }
    .title-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      #Event-Title {
        display: flex;
      }

      #Reset-Btn {
        display: none;
        justify-content: center;
        margin-left: 15px;
        padding: 3px 0;
        width: 80px;
        height: 25px;
        font-family: inherit;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        color: #ffffff;
        border: none;
        border-radius: 15px;
        background: var(--secondary-blue);
        outline: none;
        cursor: pointer;
      }
    }
    // end title-block

    .chart-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .chart-wrapper {
        width: 352px;

        @media screen and (max-width: 1124px) {
          width: 49%;
        }

        @media screen and (max-width: 675px) {
          width: 100%;
        }

        .chart-medium {
          border-radius: 15px;
          margin: 0px 0px 21px 0px;
          background-image: var(--dashboard-tiles);
          -webkit-box-shadow: var(--dashboard-shadow);
          -moz-box-shadow: var(--dashboard-shadow);
          box-shadow: var(--dashboard-shadow);
          border: 1px solid var(--dashboard-border);

          .chart-header {
            display: flex;
            align-items: center;
            padding: 0px 20px 0px 20px;
            width: 100%;
            height: 30px;
            justify-content: space-between;

            .dark-text {
              color: #697e8a;
            }
          }
          // end chart-header
        }
        // end chart-medium/chart-small

        .chart-medium {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 322px;

          .chart-chart-full {
            width: 100%;
            height: 292px;
          }

          .chart-chart {
            width: 100%;
            height: 212px;
          }

          .chart-values {
            width: 100%;
            height: 80px;

            .chart-direction,
            .chart-totals {
              width: 100%;
              margin: 10px 0px 10px 20px;

              h2,
              p {
                margin: 0px;
              }
            }

            .chart-direction {
              display: flex;

              .icon {
                position: relative;
                top: 0;
                left: 0;
              }

              &.increase {
                color: #43b777;
              }

              &.decrease {
                color: #b94e4e;
              }

              p {
                margin-left: 15px;
              }
            }
          }
        }
        // end chart-medium
      }
      // end chart-wrapper
    }
    // end chart-container
  }
  // end Content
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import PastEvent from "../components/PastEvent.vue";
import LiveEvent from "../components/LiveEvent.vue";
import SmallChart from "@/components/SmallChart.vue";
import ChartTimeframeSelector from "@/components/ChartTimeframeSelector.vue";
import PromoterLinkModal from "../components/modals/PromoterLinkModal.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEllipsisH,
  faArrowUp,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Chart from "chart.js";
import { saveToClipboard } from "@/helpers/clipboard";
import InfoText from "@/components/InfoText.vue";
import TabTote from "../../../shared/components/TabTote.vue";

library.add(faEllipsisH, faArrowUp, faArrowDown);

export default {
  name: "dashboard",
  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    PastEvent,
    LiveEvent,
    SmallChart,
    ChartTimeframeSelector,
    PromoterLinkModal,
    InfoText,
    TabTote
  },
  head() {
    return {
      title: "Dashboard"
    };
  },
  data() {
    return {
      chartData: {
        earnings: [],
        statistics: [],
        clicks: [],
        promo_codes: [],
        commission: [],
        earnings: [],
        tickets: [[], [], []],
        ticketsQuantity: [],
        ticketsRevenue: [],
        visits: [],
        sales: []
      },
      totalData: {
        clicks: [],
        promo_codes: [],
        earnings: [],
        commission: [],
        ticketsQuantity: [],
        ticketsRevenue: [],
        tickets: [
          [[], [], []],
          [[], [], []],
          [[], [], []]
        ]
      },
      changeData: {
        clicks: {},
        promo_codes: {},
        earnings: {},
        commission: {},
        ticketsQuantity: {},
        ticketsRevenue: {},
        tickets: [
          [[], [], []],
          [[], [], []],
          [[], [], []]
        ]
      },
      weeklyChartData: {
        earnings: [],
        clicks: [],
        promo_codes: [],
        commission: [],
        earnings: [],
        tickets: [[], [], []],
        ticketsQuantity: [],
        ticketsRevenue: []
      },
      current: [],
      past: [],
      data: {},
      tickets: [[], [], []],
      timeframeOptions: [
        { label: "1 Month", value: 1 },
        { label: "6 Months", value: 6 },
        { label: "1 Year", value: 12 }
      ],
      charts: {
        earnings: null,
        statistics: null,
        clicks: null,
        promo_codes: null,
        commission: null,
        ticketsQuantity: null,
        ticketsRevenue: null,
        tickets: [[], [], []]
      },
      changes: {
        clicks: { class: "unchanged", value: "0.00%" },
        promo_codes: { class: "unchanged", value: "0.00%" },
        earnings: { class: "unchanged", value: "0.00%" },
        commission: { class: "unchanged", value: "0.00%" },
        ticketsQuantity: { class: "unchanged", value: "0.00%" },
        ticketsRevenue: { class: "unchanged", value: "0.00%" },
        tickets: [[], [], []]
      },
      totals: {},
      weeks: ["1st - 7th", "8th - 14th", "15th - 21st", "22nd - 28th", "29th+"],
      statisticsTimeframe: "1 Year",
      commissionTimeframe: "1 Year",
      link: "",
      navTabs: ["Current Live Events", "Previous Events"],
      navTabSelected: 0
    };
  },
  computed: {
    totalEarnedForPlannerCurrencyChange() {
      return (
        this.currentStoreCurrency.name +
        " " +
        (
          this.currentStoreCurrency.value * parseInt(this.totals.earnings)
        ).toFixed(2)
      );
    },
    totalCommissionCurrencyChange() {
      return (
        this.currentStoreCurrency.name +
        " " +
        (
          this.currentStoreCurrency.value * parseInt(this.totals.commission)
        ).toFixed(2)
      );
    },
    totalTicketsRevenueCurrencyChange() {
      return (
        this.currentStoreCurrency.name +
        " " +
        (
          this.currentStoreCurrency.value * parseInt(this.totals.ticketsRevenue)
        ).toFixed(2)
      );
    },
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    months() {
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return this.shiftArray(months, this.shift);
    },
    indexes() {
      let indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      return this.shiftArray(indexes, this.shift);
    },
    shift() {
      return 11 - new Date().getMonth();
    }
  },
  methods: {
    pageURL() {
      const pv = this.$store.state.user.pv_url;
      if (process.env.NODE_ENV !== "production") {
        return this.createBuyersLink("affiliate/" + pv);
      } else {
        return this.createBuyersLink("affiliate/" + pv, {
          domainName: "nmproaccess.com"
        });
      }
    },
    redirect() {
      window.open(this.pageURL(), "_blank");
    },
    setData(name, chartData, totalData) {
      this.chartData[name] = chartData;
      if (totalData != null) {
        this.changeData[name][0] = this.changeData[
          name
        ][1] = this.getChangeData(this.chartData[name]);
        this.totalData[name][0] = totalData(12);
        this.totalData[name][1] = totalData(6);
      }
    },
    setWeeklyData(name, weeklyData, weeklyTotalData) {
      this.weeklyChartData[name] = weeklyData;
      if (weeklyTotalData != null) {
        this.changeData[name][2] = this.getChangeData(
          this.weeklyChartData[name]
        );
        this.totalData[name][2] = weeklyTotalData;
      }
    },
    loadCombinedStatistics() {
      let earnings = this.newEmptyMonthArray();
      let commission = this.newEmptyMonthArray();
      let ticketsQuantity = this.newEmptyMonthArray();
      let ticketsRevenue = this.newEmptyMonthArray();
      let weeklyEarnings = [0, 0, 0, 0, 0];
      let weeklyCommission = [0, 0, 0, 0, 0];
      let weeklyTicketsQuantity = [0, 0, 0, 0, 0];
      let weeklyTicketsRevenue = [0, 0, 0, 0, 0];

      this.tickets.forEach((t, i, a) => (a[i] = []));

      for (let o of this.data.earnings) {
        let date = o.date;
        let earned = new Number(o.earned);
        let index = this.indexes.indexOf(date.getMonth());

        earnings[index] += earned;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), earned, weeklyEarnings);
      }

      for (let c of this.data.commission) {
        let date = c.date;
        let earned = new Number(c.earned);
        let index = this.indexes.indexOf(date.getMonth());

        commission[index] += earned;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), earned, weeklyCommission);
      }

      this.data.tickets.forEach(t => {
        let index = this.indexes.indexOf(t.date.getMonth());

        ticketsQuantity[index] += t.quantity;
        ticketsRevenue[index] += new Number(t.revenue);

        if (t.date.getMonth() === this.indexes[11]) {
          this.pushIntoWeeklyArray(
            t.date.getDate(),
            t.quantity,
            weeklyTicketsQuantity
          );
          this.pushIntoWeeklyArray(
            t.date.getDate(),
            new Number(t.revenue),
            weeklyTicketsRevenue
          );
        }
      });

      this.setData("clicks", this.data.clicks, offset =>
        this.data.clicks.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData("promo_codes", this.data.promo_codes, offset =>
        this.data.promo_codes.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData("earnings", earnings, offset =>
        earnings
          .slice(-offset)
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
      );
      this.setData("commission", commission, offset =>
        commission
          .slice(-offset)
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
      );
      this.setData("ticketsQuantity", ticketsQuantity, offset =>
        this.data.tickets.map(t => t.quantity).reduce((a, b) => a + b, 0)
      );
      this.setData("ticketsRevenue", ticketsRevenue, offset =>
        ticketsRevenue.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setData("statistics", [
        this.data.visits[0],
        commission.reduce((a, b) => a + b, 0).toFixed(2),
        this.data.sales[0]
      ]);
      this.setData("visits", this.data.visits);
      this.setData("sales", this.data.sales);

      this.setWeeklyData(
        "clicks",
        this.data.weekly.clicks,
        this.data.weekly.clicks.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "promo_codes",
        this.data.weekly.codes,
        this.data.weekly.codes.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "earnings",
        weeklyEarnings,
        "$" + weeklyEarnings.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "commission",
        weeklyCommission,
        "$" + weeklyCommission.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "ticketsQuantity",
        weeklyTicketsQuantity,
        weeklyTicketsQuantity.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "ticketsRevenue",
        weeklyTicketsRevenue,
        weeklyTicketsRevenue.reduce((a, b) => a + b, 0)
      );

      let changesObj = {};
      Object.keys(this.changeData).forEach(key => {
        changesObj[key] = this.changeData[key][0];
      });

      let totalsObj = {};
      Object.keys(this.totalData).forEach(key => {
        totalsObj[key] = this.totalData[key][0];
      });

      this.changes = changesObj;
      this.totals = totalsObj;

      document.getElementById("Event-Title").innerHTML =
        "All Events Combined Statistics Overview";
      document.getElementById("Reset-Btn").style.display = "none";

      this.$nextTick(() => this.loadCharts());
    },
    loadEventStatistics(event) {
      let earnings = this.newEmptyMonthArray();
      let commission = this.newEmptyMonthArray();
      let ticketsQuantity = this.newEmptyMonthArray();
      let ticketsRevenue = this.newEmptyMonthArray();
      let weeklyEarnings = [0, 0, 0, 0, 0];
      let weeklyCommission = [0, 0, 0, 0, 0];
      let weeklyTicketsQuantity = [0, 0, 0, 0, 0];
      let weeklyTicketsRevenue = [0, 0, 0, 0, 0];

      this.tickets.forEach((t, i, a) => (a[i] = []));

      let i = 0;
      event.tiers.forEach(t => {
        let index = this.tickets[i].push(t) - 1;
        this.chartData.tickets[i][index] = this.newEmptyMonthArray();
        this.weeklyChartData.tickets[i][index] = [0, 0, 0, 0, 0];

        i++;
        if (i > 2) i = 0;
      });

      for (let o of event.earnings) {
        let date = new Date(+o.date.$date.$numberLong);
        let earned = new Number(o.earned);
        let index = this.indexes.indexOf(date.getMonth());

        earnings[index] += earned;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), earned, weeklyEarnings);
      }

      for (let c of event.commission) {
        let date = new Date(c.date.$date.$numberLong * 1);
        let comm = new Number(c.earned);
        let index = this.indexes.indexOf(date.getMonth());

        commission[index] += comm;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), comm, weeklyCommission);
      }

      for (let o of event.tickets) {
        let date = new Date(o.date.$date.$numberLong * 1);
        let earned = new Number(o.revenue);
        let index = this.indexes.indexOf(date.getMonth());

        ticketsQuantity[index] += o.quantity;
        ticketsRevenue[index] += earned;

        if (date.getMonth() === this.indexes[11]) {
          this.pushIntoWeeklyArray(
            date.getDate(),
            o.quantity,
            weeklyTicketsQuantity
          );
          this.pushIntoWeeklyArray(
            date.getDate(),
            earned,
            weeklyTicketsRevenue
          );
        }

        let column = this.tickets.findIndex(u =>
          u.some(v => v.name === o.tier)
        );
        let row = this.tickets[column].findIndex(u => u.name === o.tier);

        this.chartData.tickets[column][row][index] += earned;
      }

      for (let i = 0; i < this.chartData.tickets.length; i++) {
        for (let j = 0; j < this.chartData.tickets[i].length; j++) {
          this.changeData.tickets[0][i][j] = this.changeData.tickets[1][i][
            j
          ] = this.getChangeData(this.chartData.tickets[i][j]);
          this.totalData.tickets[0][i][j] =
            "$" +
            this.chartData.tickets[i][j].reduce((a, b) => a + b, 0).toFixed(2);
          this.totalData.tickets[1][i][j] =
            "$" +
            this.chartData.tickets[i][j]
              .slice(-6)
              .reduce((a, b) => a + b, 0)
              .toFixed(2);
        }
      }

      this.setData("clicks", event.clicks, offset =>
        event.clicks.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData("promo_codes", event.promo_codes, offset =>
        event.promo_codes.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData(
        "earnings",
        earnings,
        offset =>
          "$" +
          earnings
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData(
        "commission",
        commission,
        offset =>
          "$" +
          commission
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData("ticketsQuantity", ticketsQuantity, offset =>
        event.tickets.map(t => t.quantity).reduce((a, b) => a + b, 0)
      );
      this.setData(
        "ticketsRevenue",
        ticketsRevenue,
        offset =>
          "$" +
          ticketsRevenue
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData("statistics", [
        event.visits[0],
        commission.reduce((a, b) => a + b, 0).toFixed(2),
        event.sales[0]
      ]);
      this.setData("visits", event.visits);
      this.setData("sales", event.sales);

      this.setWeeklyData(
        "clicks",
        event.weekly.clicks,
        event.weekly.clicks.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "promo_codes",
        event.weekly.codes,
        event.weekly.codes.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "earnings",
        weeklyEarnings,
        "$" + weeklyEarnings.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "commission",
        weeklyCommission,
        "$" + weeklyCommission.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "ticketsQuantity",
        weeklyTicketsQuantity,
        weeklyTicketsQuantity.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "ticketsRevenue",
        weeklyTicketsRevenue,
        weeklyTicketsRevenue.reduce((a, b) => a + b, 0).toFixed(2)
      );

      let changesObj = {};
      Object.keys(this.changeData).forEach(key => {
        changesObj[key] = this.changeData[key][0];
      });

      let totalsObj = {};
      Object.keys(this.totalData).forEach(key => {
        totalsObj[key] = this.totalData[key][0];
      });

      this.changes = changesObj;
      this.totals = totalsObj;

      document.getElementById("Event-Title").innerHTML =
        event.name + " Statistics Overview";
      document.getElementById("Reset-Btn").style.display = "block";
      window.scrollTo({ top: 125, behavior: "smooth" });

      this.$nextTick(() => this.loadCharts());
    },
    loadCharts() {
      if (!this.charts.commission) {
        this.charts.commission = new Chart("commission", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Earned",
                data: this.chartData.commission,
                backgroundColor: ["#ac5c00"],
                borderColor: ["#FF8A00"],
                pointRadius: 0,
                pointHitRadius: 5,
                lineTension: 0
              }
            ]
          },
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: {
                fontColor: "#FF8A00"
              }
            },
            layout: {
              padding: {
                right: 20
              }
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  return `${
                    data.datasets[tooltip.datasetIndex].label
                  }: $${tooltip.yLabel.toFixed(2)}`;
                }
              }
            }
          }
        });
      } else {
        this.charts.commission.data.labels = this.months;
        this.charts.commission.data.datasets[0].data = this.chartData.commission;
        this.charts.commission.update();
      }

      if (!this.charts.statistics) {
        this.charts.statistics = new Chart("statistics", {
          data: {
            datasets: [
              {
                data: this.chartData.statistics,
                backgroundColor: ["#1a6687", "#2f6c3c", "#ac5c00"],
                borderColor: ["#2bacd9", "#43b777", "#FF8A00"],
                pointHitRadius: 5
              }
            ],
            labels: ["Page Visits", "Earnings", "Sales"]
          },
          type: "doughnut",
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: {
                fontColor: "#FF8A00"
              }
            },
            layout: {
              padding: {
                bottom: 10
              }
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  let label = data.labels[tooltip.index];
                  if (label === "Earnings")
                    return `${label}: $${data.datasets[0].data[tooltip.index]}`;
                  return `${label}: ${data.datasets[0].data[tooltip.index]}`;
                }
              }
            }
          }
        });
      } else {
        this.charts.statistics.data.datasets[0].data = this.chartData.statistics;
        this.charts.statistics.update();
      }

      if (!this.charts.clicks) {
        this.charts.clicks = new Chart("clicks", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Link Clicks",
                data: this.chartData.clicks,
                borderColor: ["#FF8A00"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false
            }
          }
        });
      } else {
        this.charts.clicks.data.labels = this.months;
        this.charts.clicks.data.datasets[0].data = this.chartData.clicks;
        this.charts.clicks.update();
      }

      if (!this.charts.promo_codes) {
        this.charts.promo_codes = new Chart("promo-code", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Codes Used",
                data: this.chartData.promo_codes,
                borderColor: ["#FF8A00"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false
            }
          }
        });
      } else {
        this.charts.promo_codes.data.labels = this.months;
        this.charts.promo_codes.data.datasets[0].data = this.chartData.promo_codes;
        this.charts.promo_codes.update();
      }

      if (!this.charts.earnings) {
        this.charts.earnings = new Chart("earnings", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Earnings",
                data: this.chartData.earnings,
                borderColor: ["#FF8A00"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  return `${
                    data.datasets[tooltip.datasetIndex].label
                  }: $${tooltip.yLabel.toFixed(2)}`;
                }
              }
            }
          }
        });
      } else {
        this.charts.earnings.data.labels = this.months;
        this.charts.earnings.data.datasets[0].data = this.chartData.earnings;
        this.charts.earnings.update();
      }

      if (!this.charts.ticketsQuantity) {
        this.charts.ticketsQuantity = new Chart("tickets-quantity", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Tickets Sold",
                data: this.chartData.ticketsQuantity,
                borderColor: ["#FF8A00"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false
            }
          }
        });
      } else {
        this.charts.ticketsQuantity.data.labels = this.months;
        this.charts.ticketsQuantity.data.datasets[0].data = this.chartData.ticketsQuantity;
        this.charts.ticketsQuantity.update();
      }

      if (!this.charts.ticketsRevenue) {
        this.charts.ticketsRevenue = new Chart("tickets-revenue", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Ticket Revenue",
                data: this.chartData.ticketsRevenue,
                borderColor: ["#FF8A00"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  return `${
                    data.datasets[tooltip.datasetIndex].label
                  }: $${tooltip.yLabel.toFixed(2)}`;
                }
              }
            }
          }
        });
      } else {
        this.charts.ticketsRevenue.data.labels = this.months;
        this.charts.ticketsRevenue.data.datasets[0].data = this.chartData.ticketsRevenue;
        this.charts.ticketsRevenue.update();
      }

      this.charts.tickets.forEach(a => a.forEach(c => c.destroy()));
      this.charts.tickets = [[], [], []];

      let tickets = document.getElementsByClassName("ticket-graph");
      for (let graph of tickets) {
        let indexes = graph.id
          .split("-")
          .slice(1)
          .map(i => i * 1);
        let chart = new Chart(graph, {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Ticket Sales",
                data: this.chartData.tickets[indexes[0]][indexes[1]],
                borderColor: ["#FF8A00"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  return `${
                    data.datasets[tooltip.datasetIndex].label
                  }: $${tooltip.yLabel.toFixed(2)}`;
                }
              }
            }
          }
        });

        this.charts.tickets[indexes[0]].push(chart);
      }
    },
    newEmptyMonthArray() {
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },
    setChartTimeframe(chart, data, offset) {
      const name = (obj, func) => {
        let res = {};
        Object.keys(obj).forEach(key => {
          res[key] = key;
        });
        return func(res);
      };

      if (offset === 1) {
        chart.data.labels = this.weeks;
        chart.data.datasets[0].data = data(this.weeklyChartData);

        this.changes[name(this.changes, data)] = data(this.changeData)[2];
        this.totals[name(this.totals, data)] = data(this.totalData)[2];
      } else {
        chart.data.labels = this.months.slice(-offset);
        chart.data.datasets[0].data = data(this.chartData).slice(-offset);

        if (offset === 6) {
          this.changes[name(this.changes, data)] = data(this.changeData)[1];
          this.totals[name(this.totals, data)] = data(this.totalData)[1];
        } else {
          this.changes[name(this.changes, data)] = data(this.changeData)[0];
          this.totals[name(this.totals, data)] = data(this.totalData)[0];
        }
      }

      chart.update();
    },
    setChartTimeframeStatistics(chart, offset) {
      let visits = this.chartData.visits;
      let earnings = this.chartData.earnings
        .slice(-offset)
        .reduce((a, b) => a + b);
      let sales = this.chartData.sales;
      let data = [];

      if (offset === 1) data = [visits[2], earnings, sales[2]];
      if (offset === 6) data = [visits[1], earnings, sales[1]];
      if (offset === 12) data = [visits[0], earnings, sales[0]];

      chart.data.datasets[0].data = data;
      chart.update();
    },
    setChartTimeframeText(field, value) {
      this[field] = value;
    },
    getChangeData(data) {
      data = data.slice(-2);
      let increase = data[1] - data[0];
      let result = data[0] !== 0 ? (increase / data[0]) * 100 : 0;
      return {
        class:
          result === 0 ? "unchanged" : result > 0 ? "increase" : "decrease",
        value: `${result === 0 ? "" : result > 0 ? "+" : ""}${result.toFixed(
          2
        )}%`
      };
    },
    shiftArray(array, shift) {
      if (array.length < 2) return array;

      for (let i = 0; i < shift; i++) {
        array.unshift(array.pop());
      }

      return array;
    },
    pushIntoWeeklyArray(date, value, array) {
      if (date <= 7) array[0] += value;
      else if (date <= 14) array[1] += value;
      else if (date <= 21) array[2] += value;
      else if (date <= 28) array[3] += value;
      else if (date > 28) array[4] += value;
    },
    copyPageLink() {
      saveToClipboard(this.link, this.$root.$refs.alert);
    },
    onClickNavTab(index) {
      const path = this.$route.path;

      this.navTabSelected = index;

      if (index === 0 && !path.endsWith("dashboard")) {
        this.$router.push("/dashboard");
      } else if (index === 1 && !path.endsWith("past")) {
        this.$router.push("/dashboard/past");
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Affiliate Dashboard");
    this.$store.commit("setSubtitle", "Affiliates");

    // this.$loader.start();
    // this.$axios
    //   .post("/promoters/dashboard")
    //   .then(response => {
    //     const data = response.data;

    //     switch (process.env.VUE_APP_ENVIRONMENT) {
    //       case "development":
    //         this.link = `http://localhost:3100/pv/${data.pv_url}`;
    //         break;
    //       case "staging":
    //         this.link = `https://staging.incredevent.com/pv/${data.pv_url}`;
    //         break;
    //       default:
    //         this.link = `https://incredevent.com/pv/${data.pv_url}`;
    //         break;
    //     }

    //     this.current = data.current;
    //     this.current.forEach(c => {
    //       c.date = new Date(c.start.$date.$numberLong * 1);
    //       c.created = new Date(c.created.$date.$numberLong * 1);
    //       c.clicks = this.shiftArray(c.clicks, this.shift);
    //       c.promo_codes = this.shiftArray(c.codes, this.shift);
    //       c.can_link = c.promoter.link && c.promoter.active;
    //     });

    //     this.past = data.past;
    //     this.past.forEach(p => {
    //       p.date = new Date(p.start.$date.$numberLong * 1);
    //       p.created = new Date(p.created.$date.$numberLong * 1);
    //       p.clicks = this.shiftArray(p.clicks, this.shift);
    //       p.promo_codes = this.shiftArray(p.codes, this.shift);
    //       p.can_link = p.promoter.link && p.promoter.active;
    //     });

    //     this.data = data.combined;
    //     this.data.clicks = this.shiftArray(this.data.clicks, this.shift);
    //     this.data.promo_codes = this.shiftArray(this.data.codes, this.shift);
    //     this.data.tickets.forEach(
    //       t => (t.date = new Date(t.date.$date.$numberLong * 1))
    //     );
    //     this.data.earnings.forEach(
    //       e => (e.date = new Date(e.date.$date.$numberLong * 1))
    //     );
    //     this.data.commission.forEach(
    //       c => (c.date = new Date(c.date.$date.$numberLong * 1))
    //     );

    //     // this.loadCombinedStatistics();
    //   })
    //   .finally(() => {
    //     this.$loader.stop();
    //   });
  }
};
</script>

<template>
  <div>
    <h3>Previous Events</h3>
    <div v-if="events" class="past-container">
      <past-event
        v-for="(event, index) in events"
        :key="index"
        :event="event"
        :pageURL="pageURL"
      />
      <div v-if="!events.length">No Previous Events</div>
    </div>
    <div v-else class="current-container">
      <Loader />
    </div>
  </div>
</template>

<script>
import PastEvent from "../../components/PastEvent.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "past-events",
  components: {
    PastEvent,
    Loader
  },
  props: {
    pageURL: String
  },
  data() {
    return {
      events: null
    };
  },
  created() {
    this.$axios.get("/promoters/dashboard/past").then(response => {
      const data = response.data.data;

      this.events = data.events;
    });
  }
};
</script>
<template>
  <div class="chart-small">
    <div class="chart-header">
      <p>
        {{ title }}
        <span class="dark-text" v-if="!noChart"> - {{ chartType }}</span>
      </p>
      <div>
        <chart-timeframe-selector
          :options="options"
          v-on:input="updateType"
          v-if="!noChart"
        />
      </div>
    </div>
    <div class="chart-values">
      <div class="chart-direction" :class="[change.class]" v-if="change">
        <font-awesome-icon
          v-if="change.class === 'increase'"
          class="icon"
          icon="arrow-up"
        />
        <font-awesome-icon
          v-else-if="change.class === 'decrease'"
          class="icon"
          icon="arrow-down"
        />
        <font-awesome-icon v-else class="icon" icon="minus" />
        <p>{{ change.value }}</p>
      </div>
      <div class="chart-totals">
        <h4>{{ total }}</h4>
      </div>
    </div>
    <div class="chart-chart">
      <canvas :id="id" :class="classes" width="200px" height="115px"></canvas>
    </div>
  </div>
</template>

<style lang="less" scoped>
.chart-small {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 150px;
  border-radius: 15px;
  margin: 0px 0px 21px 0px;
  background-image: var(--dashboard-tiles);
  -webkit-box-shadow: var(--dashboard-shadow);
  -moz-box-shadow: var(--dashboard-shadow);
  box-shadow: var(--dashboard-shadow);
  border: 1px solid var(--dashboard-border);

  .chart-header {
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 20px;
    width: 100%;
    height: 30px;
    justify-content: space-between;

    .dark-text {
      color: #697e8a;
    }
  }

  .chart-values {
    display: flex;
    flex-wrap: wrap;
    width: 39%;
    height: 120px;

    .chart-totals,
    .chart-direction {
      width: 100%;
      padding-left: 20px;
    }

    .chart-direction {
      position: relative;

      p {
        padding-left: 25px;
      }

      .icon {
        position: absolute;
        top: 12px;
        left: 20px;
      }

      &.increase {
        color: #4eb95e;
      }

      &.decrease {
        color: #b94e4e;
      }
    }
  }

  .chart-chart {
    width: 59%;
    height: 120px;
  }
}
</style>

<script>
import ChartTimeframeSelector from "@/components/ChartTimeframeSelector.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEllipsisH,
  faArrowUp,
  faArrowDown,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEllipsisH, faArrowUp, faArrowDown, faMinus);

export default {
  name: "small-chart",
  components: {
    ChartTimeframeSelector,
    FontAwesomeIcon,
  },
  props: {
    id: String,
    classes: Array | Object,
    title: String,
    change: Object,
    total: String | Number,
    noChart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        { label: "1 Month", value: 1 },
        { label: "6 Months", value: 6 },
        { label: "1 Year", value: 12 },
      ],
      chartType: "1 Year",
    };
  },
  methods: {
    updateType(val) {
      if (val == 1) {
        this.chartType = "1 Month";
      } else if (val == 6) {
        this.chartType = "6 Months";
      } else {
        this.chartType = "1 Year";
      }

      this.$emit("input", val);
    },
  },
};
</script>

<template>
  <div>
    <h3>Current Live Events</h3>
    <p class="notice">
      Attribution of sales is often instant but it can take up to 48 hours for
      some transactions to appear in your dashboard. For manual entries, the
      sale may take longer to populate.
    </p>

    <div v-if="events" class="current-container">
      <live-event
        v-for="(event, index) in events"
        :key="index"
        :event="event"
        :pageURL="pageURL"
      />
      <div v-if="!events.length">No Current Live Events</div>
    </div>
    <div v-else class="current-container">
      <Loader />
    </div>
  </div>
</template>

<script>
import LiveEvent from "../../components/LiveEvent.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "current-events",
  components: {
    LiveEvent,
    Loader
  },
  props: {
    pageURL: String
  },
  data() {
    return {
      events: null
    };
  },
  created() {
    this.$axios.get("/promoters/dashboard/current").then(response => {
      const data = response.data.data;

      this.events = data.events;
    });
  },
  mounted() {
    if (!localStorage.getItem("pp-notice")) {
      this.$store.state.bus.$emit("alert", {
        title: "PLEASE NOTE",
        msg:
          "Payment Plan transactions are delayed on the dashboard. We’ll update this message once the process completes."
      });
      localStorage.setItem("pp-notice", "read");
    }
  }
};
</script>
<style lang="less" scoped>
p.notice {
  font-size: 18px;
  margin: 0;
  margin-bottom: 20px;
}
</style>

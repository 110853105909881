<template>
  <v-dialog :max-width="width" :height="height" scrollable v-model="value">
    <div class="modal">
      <div class="modal-header" v-if="title">
        <div class="modal-header-box">
          <h3>{{ title }}</h3>
          <p v-if="subTitle">{{subTitle}}</p>
        </div>
        <!-- <button class="close" v-if="closable" v-on:click="close">
              &times;
            </button> -->
      </div>
      <div class="modal-body">
        <div class="modal-content b1">
          <slot></slot>
        </div>
        <!-- <div class="modal-footer" v-if="footer"> -->
        <slot name="footer"></slot>
        <!-- </div> -->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "TTDialog",
  components: {
    DiyoboButton,
  },
  props: {
    active: Boolean,
    title: String,
    subTitle: String,
    closable: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String | Number,
      default: "496px",
    },
    height: {
      type: String | Number,
      default: "500",
    },
  },
  computed: {
    value: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("close");
      },
    },
  },
};
</script>

<style lang="less">
.v-dialog {
  background-color: var(--content-base);
  border-radius: 8px;
}

.modal {
  // background-color: var(--content-base);

  &.active {
    opacity: 1;

    .modal-inner {
      .modal-dialog {
        top: 0;
      }
      // end modal-dialog
    }
    // end modal-inner
  }
  // #d9d9d9c4

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-bottom: 0px;
    min-height: 10px;
    border-radius: 5px 5px 0 0;

    h2,
    h3 {
      margin: 0 !important;
      font-weight: bold;
      color: var(--text);
    }

    &-box {
      background-color: var(--input-background);
      text-transform: uppercase;
      padding: 16px;
      border-radius: 8px;
      width: 100%;
    }

    .close {
      padding: 0;
      position: relative;
      font-size: 2em;
      color: #666666;
      background: transparent;
      border: none;
      cursor: pointer;
      -webkit-appearance: none;
      outline: none;
    }
  }

  .modal-body {
    padding: 16px 16px 16px 16px;
    // box-shadow: inset 0 0 3px 1px #0000008a;
    background-color: var(--content-base);
    border-radius: 0 0 5px 5px;

    .modal-content {
      margin-bottom: 20px;

      &::v-deep > h3 {
        margin-top: 0;
      }

      // &::v-deep div {
      //   margin-bottom: 18px;
      // }
    }
    // end modal-content

    .modal-footer {
      display: flex;
      justify-content: flex-end;
    }
    // end modal-footer

    .error-label {
      margin-bottom: 0.75em;
      color: #e60022;
      text-align: center;
      font-weight: 600;
    }
  }
  // end modal-body

  .buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  }
}
// end modal
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-wrapper",class:{ error: _vm.error }},[_c('div',{ref:"dropdown",staticClass:"menu",class:{ active: _vm.expanded },on:{"click":function($event){return _vm.expand()}}},[_c('font-awesome-icon',{staticClass:"angle-icon",attrs:{"icon":"angle-down"}})],1),_c('div',{ref:"options",staticClass:"options",class:{ expanded: _vm.sendClick }},[_c('div',{staticClass:"options-inner",class:{ expanded: _vm.expanded },on:{"click":_vm.close}},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"option-wrapper"},[(option.dummy)?_c('div',{staticClass:"option inactive"}):(typeof option.icon !== "string")?_c('div',{staticClass:"option",on:{"click":function($event){return _vm.$emit('redirect', option.value)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":option.icon}}),_c('div',{staticClass:"option-label"},[_vm._v(_vm._s(option.label))])],1):(
            option.inactive &&
              option.type !== 'danger' &&
              option.type !== "postpone" &&
              option.type !== 'cancel'
          )?_c('div',{staticClass:"option inactive"},[_c('img',{staticClass:"content-image",attrs:{"src":require(("@/images/eventsicons/" + (option.icon))),"alt":option.label}}),_c('div',{staticClass:"option-label"},[_vm._v(_vm._s(option.label))])]):(option.type == "1")?_c('router-link',{staticClass:"option 1",attrs:{"to":((option.link) + "/" + _vm.eventUrl)}},[_c('img',{staticClass:"content-image",attrs:{"src":require(("@/images/eventsicons/" + (option.icon))),"alt":option.label}}),_c('div',{staticClass:"option-label"},[_vm._v(_vm._s(option.label))])]):(option.type == 'external')?_c('div',{staticClass:"option redirect",on:{"click":function($event){return _vm.$emit('redirect', option.value)}}},[_c('img',{staticClass:"content-image",attrs:{"src":require(("@/images/eventsicons/" + (option.icon))),"alt":option.label}}),_c('div',{staticClass:"option-label"},[_vm._v(_vm._s(option.label))])]):_vm._e(),(
            option.type == "danger" && !option.inactive && !_vm.deactivateEligible
          )?_c('div',{staticClass:"mask"}):_vm._e(),(option.type == "danger" && !option.inactive)?_c('div',{staticClass:"option-danger",attrs:{"id":option.label},on:{"click":function($event){return _vm.optionDangerFunc(option.label)}}},[_c('img',{staticClass:"content-image",attrs:{"src":require(("@/images/eventsicons/" + (option.icon))),"alt":option.label}}),(_vm.eventActive == true || _vm.eventActive == undefined)?_c('div',{staticClass:"option-label"},[_vm._v(" Deactivate ")]):_c('div',{staticClass:"option-label"},[_vm._v("Activate")])]):_vm._e(),(option.type == "postpone" && !option.inactive)?_c('div',{staticClass:"option-danger",attrs:{"id":option.label},on:{"click":function($event){return _vm.postponeFunc(option.label)}}},[_c('img',{staticClass:"content-image",attrs:{"src":require("@/images/icons/icon-stopwatch-white.png"),"alt":option.label}}),(!_vm.eventPostponed)?_c('div',{staticClass:"option-label"},[_vm._v("Postpone")]):_c('div',{staticClass:"option-label"},[_vm._v("Resume")])]):_vm._e(),(option.type == "cancel" && !option.inactive && !_vm.cancelled)?_c('div',{staticClass:"option-cancel",on:{"click":function($event){return _vm.$emit('cancel', _vm.eventUrl)}}},[_c('img',{staticClass:"content-image",attrs:{"src":require(("@/images/eventsicons/" + (option.icon))),"alt":"Cancel"}}),_c('div',{staticClass:"option-label"},[_vm._v(_vm._s(option.label))])]):(option.type == "cancel" && !option.inactive && _vm.cancelled)?_c('div',{staticClass:"option-cancel"},[_c('img',{staticClass:"content-image",attrs:{"src":require(("@/images/eventsicons/" + (option.icon))),"alt":"Cancelled"}}),_c('div',{staticClass:"option-label"},[_vm._v("Cancelled")])]):_vm._e()],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="info-text">
    <div class="info-text-inner">
      <div class="icon" v-if="icon">
        <font-awesome-icon icon="info-circle" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less" scoped>
.info-text {
  display: flex;
  justify-content: center;
  background-color: @colors[info-text-bg];
  border-radius: 8px;
  border: 1px solid @colors[info-text-border];
  box-shadow: @colors[info-text-shadow];

  .icon {
    color: #53b85a;
    margin-top: 12px;
    padding-right: 15px;
    font-size: 3em;
  }

  .info-text-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
  }
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faInfoCircle);

export default {
  name: "info-text",
  components: {
    FontAwesomeIcon
  },
  props: {
    icon: {
      type: Boolean,
      default: false
    }
  }
};
</script>
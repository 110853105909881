export function saveToClipboard(value, alertModal) {
  try {
    let successful = navigator.clipboard.writeText(value);
    let msg = successful ? "successful!" : "unsuccessful!";
  } catch (err) {
    if (alertModal)
      alertModal.open({
        msg:
          "There was an error saving the link to the clipboard. Try copying the link manually.",
      });
    console.error("There was an error saving to the clipboard!", err);
  }
}

<template>
  <div class="timeframe-selector">
    <div class="button" v-on:click="toggle">
      <font-awesome-icon icon="ellipsis-h" />
    </div>
    <div class="options" ref="options" :class="{ expanded }">
      <div class="options-inner" :class="{ expanded }">
        <div
          v-for="option in options"
          class="option"
          :key="option.label"
          v-on:click="setOption(option.value, option.label)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.timeframe-selector {
  .button {
    cursor: pointer;
  }

  .options {
    position: relative;
    z-index: 3;
    width: 100%;
    user-select: none;

    &.expanded {
      z-index: 11;
    }

    .options-inner {
      position: absolute;
      z-index: 3;
      width: 100px;
      right: -40px;
      max-height: 0;
      background-image: var(--dashboard-tiles);
      // background-color: #303030;
      border: 1px solid #686c7700;
      color: #6a6d79;
      overflow: hidden;
      border-radius: 5px;
      transition: 0.4s;

      &.expanded {
        transition: 0.8s;
        z-index: 5;
        max-height: 1000px;
        box-shadow: var(--dashboard-shadow);
        border: 1px solid #686c77;
      }

      .option {
        padding: 5px 9px;
        position: relative;
        transition: 0.4s;
        cursor: pointer;

        &:hover {
          padding-left: 12px;
          padding-right: 12px;
          background-color: var(--dropdown-hover);
        }

        &::after {
          content: "";
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          // width: 95%;
          border-bottom: 1px solid #6a6d79;
        }

        &:last-child::after {
          border-bottom: none;
        }
      }
      // end option
    }
    // end option-inner
  }
  // end options
}
</style>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "chart-timeframe-selector",
  components: {
    FontAwesomeIcon
  },
  props: {
    options: Array
  },
  data() {
    return {
      expanded: false,
      value: null
    };
  },
  methods: {
    toggle() {
      if (this.expanded) this.close();
      else this.expanded = true;
    },
    close() {
      let options = this.$refs.options;

      options.style.zIndex = 5;

      this.expanded = false;

      setTimeout(() => {
        options.removeAttribute("style");
      }, 400);
    },
    setOption(value, label) {
      this.$emit("input", value, label);
      this.close();
    }
  }
};
</script>
